import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { Info } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

const styles = {
  ltr: {
    right: "0.75rem",
    left: "unset",
  },
  rtl: {
    right: "unset",
    left: "0.75rem",
  },
  svg: {
    position: "absolute",
    top: "50%",
    cursor: "help",
    transform: "translateY(-50%)",
  },
};

interface TextFieldProps {
  input: {
    type: string;
    name: string;
    placeholder?: string;
    defaultValue?: any;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
  label: {
    text: string;
  };
  error?: string;
  errorMode?: string;
  className?: string;
  dir?: "ltr" | "rtl";
  prefix?: { text: string };
  postfix?: { text: string };
  small?: string;
  more?: () => React.ReactNode;
}
function TextField({
  input,
  label,
  error,
  errorMode = "subtitle",
  className,
  dir = "ltr",
  prefix,
  postfix,
  small,
  more,
}: TextFieldProps) {
  const [isActive, setIsActive] = React.useState(true);
  const { t } = useTranslation();

  const handleError = () => {
    let iconStyle = styles.svg;
    if (dir === "ltr") iconStyle = { ...iconStyle, ...styles.ltr };
    else iconStyle = { ...iconStyle, ...styles.rtl };

    if (!isEmpty(error))
      return <span style={iconStyle as React.CSSProperties}>❗️</span>;
  };

  return (
    <div className={className}>
      {!isEmpty(label) && (
        <label htmlFor={input.name} className="mb-1 mt-3 block">
          {label.text}
        </label>
      )}
      <div
        className={classnames("relative", {
          "alert-validate": error && errorMode === "embedded" && !isActive,
          ltr: dir === "ltr",
        })}
        data-validate={errorMode === "embedded" ? t(error) : ""}
      >
        <input
          dir={dir}
          className={classnames(
            "input input-bordered input-md m-0 h-[45px] w-full",
            {
              "input-error": !isEmpty(error),
            },
          )}
          {...input}
          onFocus={(e) => {
            setIsActive(true);
            input.onFocus?.(e);
          }}
          onBlur={(e) => {
            setIsActive(false);
            input.onBlur?.(e);
          }}
        />

        {!isEmpty(postfix) && (
          <label
            htmlFor={input.name}
            className="absolute left-0 top-0 flex flex-col p-1"
          >
            <div className="flex items-center justify-center self-stretch rounded bg-neutral-900 px-4 py-2">
              <div className="font-['IRANYekanX'] text-sm font-medium leading-normal text-neutral-200">
                {postfix.text}
              </div>
            </div>
          </label>
        )}

        {errorMode === "embedded" && handleError()}
      </div>
      {error && errorMode === "subtitle" && (
        <small className="text-red-600 ps-1 flex gap-1 mt-2">
          <Info size={16} />
          {t(error)}
        </small>
      )}
      {!error && small && <small className="text-secondary">{small}</small>}
      {more && more()}
    </div>
  );
}

export default TextField;
